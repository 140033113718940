<template>
  <div class="menu-edit-page w-100">
    <basic-subheader>
      <template v-slot:actions>
        <b-button class="btn ml-3" type="button" @click="returnPage">
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Arrows/arrow-left.svg" />
          </span>
          Huỷ
        </b-button>
        <b-button
          v-if="showBtnActive"
          class="btn btn-primary ml-3"
          type="button"
          @click="showPopupChangeStatus(1)"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/power.svg" />
          </span>
          Active
        </b-button>
        <b-button
          v-else
          class="btn btn-danger ml-3"
          type="button"
          @click="showPopupChangeStatus(0)"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/power.svg" />
          </span>
          Inactive
        </b-button>
        <b-button
          class="btn btn-success ml-3"
          type="button"
          :disabled="loading"
          @click="submit"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Lưu
        </b-button>
      </template>
    </basic-subheader>
    <b-container fluid class="menu-edit-page__body mb-6 mt-6">
      <b-overlay :show="loading">
        <FormCoach
          ref="formCoach"
          :is-update="true"
          :id="id"
          @submit="updateCoachGroup"
        />
      </b-overlay>
    </b-container>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import {
  SET_SELECTED_PATIENTS,
  GET_PREPARE,
  CHANGE_STATUS,
  RESET_STATE,
} from '@/core/services/store/coach.module';
const { mapMutations, mapActions, mapState } = createNamespacedHelpers('coach');
export default {
  name: 'CoachUpdate',
  components: {
    FormCoach: () => import('./components/FormCoach.vue'),
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  created() {
    this.setupData();
  },
  computed: {
    ...mapState(['groupDetail']),
    showBtnActive() {
      if ([0, 2].includes(this.groupDetail?.status)) return true;
      return false;
    },
  },
  beforeDestroy() {
    this.RESET_STATE();
  },
  methods: {
    ...mapMutations({ SET_SELECTED_PATIENTS, RESET_STATE }),
    ...mapActions({ GET_PREPARE, CHANGE_STATUS }),
    async setupData() {
      this.loading = true;
      await this.GET_PREPARE({ id: this.id });
      this.loading = false;
    },
    async returnPage() {
      let confirm = await this.$swal({
        text: 'Các thay đổi sẽ không được lưu trữ, bạn có chắc muốn huỷ?',
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Hủy',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: 'btn-success',
            closeModal: true,
          },
        },
      });
      if (confirm) {
        this.$router.go(-1);
      }
    },
    async updateCoachGroup(params) {
      this.loading = true;
      try {
        if (params.file) {
          const fileId = await this.uploadImage(params.file);
          if (!fileId) return;
          delete params.file;
          params['coverId'] = fileId;
        }
        const { meta, error, data } = await this.$api.put(
          `/TrainingGroup/${params.id}`,
          params,
        );
        if (!meta.success) {
          this.$toastr.e({
            title: 'Lỗi!',
            msg: error.message,
            preventDuplicates: true,
          });
          return;
        }
        this.$toastr.s({
          title: 'Thành công!',
          msg: 'Chỉnh sửa nhóm thành công',
        });
        this.$router.push({
          name: 'coach_detail',
          params: {
            id: data.id,
          },
        });
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          preventDuplicates: true,
          msg: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async uploadImage(file) {
      // upload file
      const { error, data } = await this.$store.dispatch(
        'context/UPLOAD_IMAGE',
        file,
      );
      if (error) {
        this.$toastr.s({
          title: 'Lỗi !',
          msg: error,
        });
        return null;
      }

      return data.id;
    },
    submit() {
      this.$refs.formCoach.handleSubmit();
    },
    showPopupChangeStatus(state) {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn chuyển trạng thái thành ${
          state ? 'Active' : 'Inactive'
        } không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            // inactive all
            this.changeStatus(state);
          }
        }.bind(this),
      );
    },
    async changeStatus(state) {
      this.loading = true;
      const params = {
        id: this.id,
        state,
      };
      const { error } = await this.CHANGE_STATUS(params);
      if (!error) {
        this.$toastr.s({
          title: 'Thành công !',
          msg: 'Cập nhật thành công',
        });
        this.setupData();
      } else {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-danger {
  background-color: #ff5756 !important;
  border-color: #ff5756 !important;
  color: #fff !important;
}
</style>
